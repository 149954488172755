<template>
  <div class="App">
    <header>
      <h1 class="header__title">Real-Time Transcription</h1>
      <p class="header__sub-title">Try AssemblyAI's new real-time transcription endpoint!</p>
    </header>
    <div class="real-time-interface">
      <p id="real-time-title" class="real-time-interface__title">Click start to begin recording!</p>
      <button class="real-time-interface__button" @click="isRecording ? endTranscription($event) : startTranscription($event)">
        {{ isRecording ? 'Stop recording' : 'Record' }}
      </button>
    </div>
    <div class="real-time-interface__message">
      {{ transcript }}
    </div>
  </div>
</template>

<script>
import * as RecordRTC from 'recordrtc';
import {AssemblyAI} from 'assemblyai';
const client = new AssemblyAI({
  apiKey: 'c595a72a82b945c5819d5971920ff012',
})



export default {
  name: 'App',
  data() {
    return {
      websocket: null,
      recorder: null,
      isRecording: false,
      transcript: '',
    };
  },
  methods: {
    async startTranscription(event) {
      event.preventDefault();

      const apiKey = 'c595a72a82b945c5819d5971920ff012';
      const texts = {};


      const token = "4e44c474960ae501204a10240880bc7d904508acbe038f17da41316cbcc68c96"

      this.websocket = new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

      this.websocket.onopen = () => {
        console.log('WebSocket connection established');
        this.isRecording = true;
      };

      this.websocket.onmessage = (event) => {
        const response = JSON.parse(event.data);
        if (response.message_type === 'PartialTranscript') {
          let msg = '';
          texts[response.audio_start] = response.text;
          const keys = Object.keys(texts);
          keys.sort((a, b) => a - b);
          for (const key of keys) {
            if (texts[key]) {
              msg += ` ${texts[key]}`;
              console.log(msg);
            }
          }
          this.transcript = msg;
        }
      };

      this.websocket.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.websocket.close();
        this.websocket = null;
      };

      this.websocket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event.code} ${event.reason}`);
        this.websocket = null;
      };

      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          this.recorder = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm;codecs=pcm',
            recorderType: RecordRTC.StereoAudioRecorder,
            timeSlice: 250,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1,
            bufferSize: 4096,
            audioBitsPerSecond: 128000,
            ondataavailable: async (blob) => {
              if (!this.websocket) return;
              const buffer = await blob.arrayBuffer();
              if (this.websocket.readyState === WebSocket.OPEN) {
                this.websocket.send(buffer);
              }
            },
          });
          this.recorder.startRecording();
        })
        .catch((err) => console.error(err));
    },
    async endTranscription(event) {
      event.preventDefault();
      this.isRecording = false;

      if (this.websocket) {
        this.websocket.close();
        this.websocket = null;
      }

      if (this.recorder) {
        this.recorder.stopRecording(() => {
          this.recorder.getTracks().forEach(track => track.stop());
        });
        this.recorder = null;
      }
    },
  },
};
</script>

<style>
.App {
  text-align: center;
}

.header__title {
  font-size: 2rem;
  margin: 0;
}

.header__sub-title {
  font-size: 1.2rem;
  margin: 0;
}

.real-time-interface {
  margin-top: 2rem;
}

.real-time-interface__title {
  font-size: 1.5rem;
}

.real-time-interface__button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.real-time-interface__message {
  margin-top: 2rem;
  font-size: 1rem;
  white-space: pre-wrap;
}
</style>
